var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"green","indeterminate":""}})],1),_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v("RM Requisitions")]),_c('p',{staticClass:"text-sm text-body mb-0"})]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage.permissions.includes('CREATE')),expression:"currentPage.permissions.includes('CREATE')"}],staticClass:"font-weight-bold text-capitalize ms-auto btn-primary bg-success py-3 px-6 ms-3",attrs:{"elevation":"0","dark":"","ripple":false,"height":"43"}},'v-btn',attrs,false),on),[_vm._v(" New Requisition ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table element",attrs:{"headers":_vm.headers,"items":_vm.blendPlans,"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.productionDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.productionDate))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","ripple":false,"color":_vm.planStatus(item).color}},[_vm._v(" "+_vm._s(_vm.planStatus(item).label)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"pe-4 ps-0 mb-0 text-default text-capitalize icon-move-right align-center",attrs:{"ripple":false,"icon":"","text":"","width":"auto"},on:{"click":function($event){return _vm.onNavigate(item)}}},[_vm._v(" "+_vm._s(_vm.currentPage.permissions.length > 0 ? item.planGenerationStatus == null ? "Add" : item.approvalStatus ? "View" : item.cancellationStatus ? "View" : "Edit" : "View")+" "),_c('v-icon',{staticClass:"ms-0 mt-1",attrs:{"right":"","dark":"","size":"12"}},[_vm._v(" fa fa-arrow-right ")])],1)],1)],1)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v("Items per page:")]),_c('v-text-field',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","value":_vm.itemsPerPage,"placeholder":"Items per page"},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#38bd34","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)])],1)],1),_c('NewRMRequisition',{attrs:{"dialog":_vm.dialog,"data":_vm.editedItem},on:{"update:dialog":function($event){_vm.dialog=$event},"onSave":_vm.onSave}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }