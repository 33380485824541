import axios from "axios";
import { blendModuleAPI } from "../../../config";

export default {
  async get() {
    return await axios
      .get(`${blendModuleAPI}/v1/BlendCode`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
