<template>
  <v-dialog persistent v-model="_dialog" max-width="500px">
    <v-card class="card-shadow">
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <!-- #region Card Heading -->
          <div class="card-header-padding">
            <span class="font-weight-bold text-h5 text-typo mb-0" v-if="editedItem.id">Update Blend Plan</span>
            <span class="font-weight-bold text-h5 text-typo mb-0" v-else>Create New Blend Plan</span>
          </div>
          <v-divider class="mb-5" />
          <!-- #endregion -->

          <!-- #region Form -->
          <v-form ref="frm">
            <v-row>
              <!-- #region Production Date picker -->
              <v-col>
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Production Date</label
                >
                <v-menu
                  ref="mnu_date"
                  v-model="mnu_date"
                  :close-on-content-click="false"
                  :return-value.sync="mnu_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.productionDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[(v) => !!v || 'Please select a date']"
                      hide-details="auto"
                      class="input-style font-size-input text-light-input placeholder-light input-icon mt-5"
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Date"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker color="green lighten-1" header-color="primary" v-model="editedItem.productionDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnu_date = false"> Cancel </v-btn>
                    <v-btn text color="primary" @click="$refs.mnu_date.save(editedItem.productionDate)"> OK </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- #endregion -->
              <!-- #region Production Quantity -->
              <v-col>
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Production Quantity</label
                >

                <v-text-field
                  v-model="editedItem.productionQuantity"
                  placeholder="Enter Required Quantity"
                  :rules="[(v) => !!v || 'PO Number is required']"
                  hide-details="auto"
                  class="input-style font-size-input text-light-input placeholder-light input-icon mt-5"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <!-- #endregion -->
            </v-row>
            <v-row>
              <!-- #region Blend Code Picker -->
              <v-col>
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Blend</label
                >
                <v-select
                  v-model="editedItem.blendCodeId"
                  :items="blendCodes"
                  :rules="[(v) => !!v || 'Please select a blend']"
                  item-text="name"
                  item-value="id"
                  class="input-style font-size-input text-light-input placeholder-light select-style mt-3"
                  outlined
                  height="46"
                >
                </v-select>
              </v-col>
              <!-- #endregion -->
              <!-- #region Company warehouse picker -->
              <v-col>
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Company Warehouse</label
                >
                <v-select
                  v-model="editedItem.companyWarehouseId"
                  :items="companyWarehouses"
                  :rules="[(v) => !!v || 'Please select a Warehouse']"
                  item-text="name"
                  item-value="id"
                  class="input-style font-size-input text-light-input placeholder-light select-style mt-3"
                  outlined
                  height="46"
                >
                </v-select>
              </v-col>
              <!-- #endregion -->
            </v-row>
            <v-row class="mt-n6">
              <!-- #region Blend Source Number -->
              <v-col>
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>Source Number</label
                >

                <v-text-field
                  v-model="editedItem.sourceNumber"
                  placeholder="Enter Blend Source Number"
                  :rules="[(v) => !!v || 'Blend Source Number is required']"
                  hide-details="auto"
                  class="input-style font-size-input text-light-input placeholder-light input-icon mt-5"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <!-- #endregion -->
            </v-row>
          </v-form>
          <!-- #endregion -->

          <!-- #region Card Action buttons -->
          <v-card-actions class="card-padding d-flex justify-end">
            <v-btn
              @click="close"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-light py-3 px-6"
              >Cancel</v-btn
            >

            <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
              >Save</v-btn
            >
          </v-card-actions>
          <!-- #endregion -->
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanyWarehouseService from "../../../master/CompanyWareHouse/api";
import BlendCodeService from "../../blendCode/api";
import BlendPlanMasterService from "../api";
export default {
  name: "NewRmRequisition",
  props: {
    dialog: Boolean,
    data: Object,
  },
  data() {
    return {
      editedItem: {
        productionDate: "",
        productionQuantity: 0,
        blendCodeId: 0,
        companyWarehouseId: 0,
        sourceNumber: "",
      },
      companyWarehouses: [],
      blendCodes: [],
      mnu_date: false,
    };
  },
  computed: {
    _dialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  watch: {
    date(val) {
      this.editedItem.productionDate = val;
    },
    dialog(val) {
      if (val) {
        this.editedItem = { ...this.data };
        if (this.editedItem.id) {
          this.editedItem.productionDate = this.formatDate(this.editedItem.productionDate);
        }
        this.$refs.frm.resetValidation();
      }
    },
  },
  methods: {
    async init() {
      const _warehouses = await CompanyWarehouseService.get();
      this.companyWarehouses = _warehouses;
      const _blendCodes = await BlendCodeService.get();
      this.blendCodes = _blendCodes;
      this.editedItem = { ...this.data };
      if (this.editedItem.id) {
        this.editedItem.productionDate = this.formatDate(this.editedItem.productionDate);
      }
    },
    validate() {
      return this.$refs.frm.validate();
    },
    async save() {
      if (!this.validate()) return;

      try {
        let res = null;
        if (this.editedItem.id) {
          res = await BlendPlanMasterService.updateProductionPlanMaster(this.editedItem);
        } else {
          res = await BlendPlanMasterService.createProductionPlanMaster(this.editedItem);
        }
        this.$emit("onSave", res);
        this.$swal({
          title: "Success",
          text: "Blend Plan Added Successfully.",
          type: "success",
          timer: 3000,
          icon: "success",
          showConfirmButton: false,
        });
      } catch (error) {
        this.$swal({
          title: "Error",
          text: error,
          showConfirmButton: false,
          type: "error",
          timer: 3000,
          icon: "error",
        });
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    close() {
      this._dialog = false;
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style></style>
